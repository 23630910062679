import React, { FormEvent, useState } from "react";

import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import Stars from "./Stars";
import { addRestaurant } from "../../database";

const AddRestaurantForm = () => {
  const [restaurant, setRestaurant] = useState<API.Restaurant>({
    id: "",
    coordinate: {
      longitude: 0,
      latitude: 0,
    },
    name: "New Restaurant",
    rating: 3,
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    addRestaurant(restaurant);
  }

  return (
    <Box sx={{ maxWidth: "300px", p:2, m:2 }} component={Paper}>
      <Typography variant="h6">Add a Restaurant</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          variant="standard"
          fullWidth
          value={restaurant.name}
          onChange={(e) => setRestaurant({ ...restaurant, name: e.target.value })}
          margin="normal"
        />
        <TextField
          label="Latitude (32.)"
          variant="standard"
          type="number"
          fullWidth
          value={restaurant.coordinate.latitude}
          onChange={(e) =>
            setRestaurant({
              ...restaurant,
              coordinate: {
                latitude: Number.parseFloat(e.target.value) ?? 0,
                longitude: restaurant.coordinate.longitude,
              },
            })
          }
          margin="normal"
        />
        <TextField
          label="Longitude (-16.)"
          variant="standard"
          type="number"
          fullWidth
          value={restaurant.coordinate.longitude}
          onChange={(e) =>
            setRestaurant({
              ...restaurant,
              coordinate: {
                latitude: restaurant.coordinate.latitude,
                longitude: Number.parseFloat(e.target.value) ?? 0,
              },
            })
          }
          margin="normal"
        />
        <Stars
          value={restaurant.rating}
          onChange={(val) => setRestaurant({ ...restaurant, rating: val })}
        />

        <Button type="submit" variant="contained" sx={{mt: 1}}>add</Button>
      </form>
    </Box>
  );
};

export default AddRestaurantForm;
