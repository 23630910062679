import React, { FormEvent, useEffect, useState } from "react";

import { Box, Button, Paper, TextField, Typography } from "@mui/material";

import { addBusStop } from "../../../../../database";

interface Props {
  coordinate?: API.Coordinate;
}

const AddBusStopForm: React.FC<Props> = ({ coordinate }) => {
  const [busStop, setBusStop] = useState<API.BusStop>({
    id: "",
    coordinate: {
      longitude: 0,
      latitude: 0,
    },
    name: "New Bus Stop",
  });

  useEffect(() => {
    if (coordinate) {
      setBusStop({
        ...busStop,
        coordinate,
      });
    }
  }, [coordinate, busStop]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    addBusStop(busStop);
  };

  return (
    <Box sx={{ maxWidth: "300px", p: 2 }} component={Paper}>
      <Typography variant="h6">Add a Bus Stop</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          variant="standard"
          fullWidth
          value={busStop.name}
          onChange={(e) => setBusStop({ ...busStop, name: e.target.value })}
          margin="normal"
        />
        <TextField
          label="Latitude (32.)"
          variant="standard"
          type="number"
          fullWidth
          value={busStop.coordinate.latitude}
          onChange={(e) =>
            setBusStop({
              ...busStop,
              coordinate: {
                latitude: Number.parseFloat(e.target.value) ?? 0,
                longitude: busStop.coordinate.longitude,
              },
            })
          }
          margin="normal"
        />
        <TextField
          label="Longitude (-16.)"
          variant="standard"
          type="number"
          fullWidth
          value={busStop.coordinate.longitude}
          onChange={(e) =>
            setBusStop({
              ...busStop,
              coordinate: {
                latitude: busStop.coordinate.latitude,
                longitude: Number.parseFloat(e.target.value) ?? 0,
              },
            })
          }
          margin="normal"
        />

        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 1 }}
          disabled={
            busStop.coordinate.latitude === 0 ||
            busStop.coordinate.longitude === 0
          }
        >
          add
        </Button>
      </form>
    </Box>
  );
};

export default AddBusStopForm;
