import { useParams } from "react-router-dom";
import Trip from ".";

const TripWrapper = () => {
  const { busCompany, busLine, trip } = useParams();

  if (!busCompany || !busLine || !trip) return null;

  return <Trip busCompanyId={busCompany} busLineId={busLine} id={trip} />;
};

export default TripWrapper;
