import React, { useEffect, useRef, useState } from "react";

interface MapContextInterface {
  map: google.maps.Map | null;
}

export const MapContext = React.createContext<MapContextInterface>({
  map: null,
});

interface Props {
  center: google.maps.LatLngLiteral;
  zoom: number;
  children?: React.ReactNode;
}

const MyMap: React.FC<Props> = ({ center, zoom, children }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<MapContextInterface>({ map: null });

  useEffect(() => {
    if (ref.current) {
      setMap({
        map: new window.google.maps.Map(ref.current, {
          center,
          zoom,
        }),
      });
    }
  }, [ref.current]);

  return (
    <div ref={ref} style={{ height: "500px" }}>
      <MapContext.Provider value={{ map: map.map }}>
        {children}
      </MapContext.Provider>
    </div>
  );
};

export default MyMap;
