import React, { Component, useEffect, useState } from "react";

import { Link, Outlet, useParams } from "react-router-dom";
import { getBusCompanies } from "../../database";

import {
  Breadcrumbs,
  Button,
  Typography,
  Link as MuiLink,
  Box,
  Stack,
} from "@mui/material";

function Bus() {
  const [busCompanies, setBusCompanies] = useState<API.BusCompany[]>([]);

  const { busCompany, busLine, trip } = useParams();

  useEffect(() => {
    getBusCompanies().then(setBusCompanies).catch(console.error);
  }, []);

  const breadcrumbsLinks = [busCompany, busLine, trip].filter(
    (s) => s !== undefined
  );

  return (
    <Box mt={2}>
      {busCompany ? (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <MuiLink underline="hover" color="inherit" to="" component={Link}>
              Bus
            </MuiLink>

            {breadcrumbsLinks.map((link, idx) =>
              idx === breadcrumbsLinks.length - 1 ? (
                <Typography color="text.primary" key={idx}>{link}</Typography>
              ) : (
                <MuiLink
                  underline="hover"
                  color="inherit"
                  to={breadcrumbsLinks.slice(0, idx + 1).join("/")}
                  component={Link}
                  key={idx}
                >
                  {link}
                </MuiLink>
              )
            )}
          </Breadcrumbs>
          <Outlet />
        </>
      ) : (
        <Stack direction="row" spacing={1} mt={1}>
          {busCompanies.map((busCompany) => (
            <Button
              key={busCompany.id}
              component={Link}
              to={busCompany.id}
              variant="contained"
            >
              {busCompany.name}
            </Button>
          ))}
        </Stack>
      )}
    </Box>
  );
}

export default Bus;
