import React, { ReactElement } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Spinner from "./Spinner";
import Error from "./Error";
import MyMap from "./MyMap";

const render = (status: Status): ReactElement => {
  if (status === Status.FAILURE) return <Error />;
  return <Spinner />;
};

interface Props {
  center: API.Coordinate;
  zoom?: number;
  children?: React.ReactNode;
}

const GoogleMaps: React.FC<Props> = ({ center, zoom = 4, children }) => {
  return (
    <Wrapper apiKey={"AIzaSyAC5XN5IiBF1Herb_pNn2roc71mnRtUxR4"} render={render}>
      <MyMap
        center={{ lat: center.latitude, lng: center.longitude }}
        zoom={zoom}
      >
        {children}
      </MyMap>
    </Wrapper>
  );
};

export default GoogleMaps;
