import React, { useEffect, useState } from "react";

import { getRestaurants } from "../../database";
import AddRestaurantForm from "./AddRestaurantForm";

function Restaurants() {
  const [restaurants, setRestaurants] = useState<API.Restaurant[]>([]);

  useEffect(() => {
    getRestaurants()
      .then(setRestaurants)
      .catch((e) => console.log(e.message));
  }, []);

  return (
    <div>
      <h2>Restaurants</h2>
      <AddRestaurantForm />
      {restaurants.map((r) => (
        <div key={r.id}>{r.name} ({r.rating})</div>
      ))}
    </div>
  );
}

export default Restaurants;
