// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCS_HQg5zrV5Ro0AjD4QVqHv1rFbgzPArc",
  authDomain: "sysdevio-madeiraapp.firebaseapp.com",
  projectId: "sysdevio-madeiraapp",
  storageBucket: "sysdevio-madeiraapp.appspot.com",
  messagingSenderId: "1029388607957",
  appId: "1:1029388607957:web:972875d6a689b3d5ff5d5c"
};

export const app = initializeApp(firebaseConfig);