import React from "react";

import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Typography,
} from "@mui/material";
import { Outlet, Link } from "react-router-dom";

function Layout() {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ mr: 2 }}>
              Admin-Panel
            </Typography>
            <Button component={Link} color="inherit" to="/">Home</Button>
            <Button component={Link} color="inherit" to="restaurants">Restaurants</Button>
            <Button component={Link} color="inherit" to="bus">Bus</Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Container maxWidth="lg">
        <Outlet />
      </Container>
    </>
  );
}

export default Layout;
