import { useParams } from "react-router-dom";
import BusLine from ".";

const BusLineWrapper = () => {
  const { busCompany, busLine } = useParams();

  if (!busLine || !busCompany) return null;

  return <BusLine companyId={busCompany} id={busLine} />;
};

export default BusLineWrapper;
