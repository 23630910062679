import { Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { getBusLines } from "../../../database";
import AddBusLineForm from "./AddBusLineForm";

interface Props {
  id: string;
}

const BusCompany: React.FC<Props> = ({ id }) => {
  const [busLines, setBusLines] = useState<API.BusLine[]>([]);

  const { busLine } = useParams();

  useEffect(() => {
    getBusLines(id).then(setBusLines).catch(console.error);
  }, [id]);

  return (
    <>
      {busLine ? (
        <Outlet />
      ) : (
        <>
        <Stack direction="row" spacing={1} mt={1}>
          {busLines.map((busLine) => (
            <Button
              key={busLine.id}
              component={Link}
              to={busLine.id}
              variant="contained"
            >
              {busLine.name}
            </Button>
          ))}
          </Stack>
          <AddBusLineForm busCompanyId={id} />
        </>
      )}
    </>
  );
};

export default BusCompany;
