import React, { FormEvent, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { addTrip } from "../../../../database";
import { DayOfWeek } from "../../../../schemas";

interface Props {
  busLineId: string;
  busCompanyId: string;
}

const AddTripForm: React.FC<Props> = ({ busLineId, busCompanyId }) => {
  const [trip, setTrip] = useState<API.Trip>({
    id: "",
    startTime: "00:00",
    dayOfWeek: "Mo-Fr",
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    addTrip(busCompanyId, busLineId, trip);
  };

  return (
    <Box sx={{ maxWidth: "300px", p: 2, mt: 2, mb: 2 }} component={Paper}>
      <Typography variant="h6">Add a Trip</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Start Time"
          variant="standard"
          fullWidth
          value={trip.startTime}
          onChange={(e) => setTrip({ ...trip, startTime: e.target.value })}
          margin="normal"
          type="time"
        />

        <FormControl fullWidth variant="standard" margin="normal">
          <InputLabel id="day-select-label">Day of Week</InputLabel>
          <Select
            labelId="day-select-label"
            value={trip.dayOfWeek}
            label="Bus Company"
            onChange={(e) =>
              setTrip({
                ...trip,
                dayOfWeek: e.target.value as typeof DayOfWeek[number],
              })
            }
          >
            {DayOfWeek.map((d) => (
              <MenuItem key={d} value={d}>
                {d}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button type="submit" variant="contained" sx={{ mt: 1 }}>
          add
        </Button>
      </form>
    </Box>
  );
};

export default AddTripForm;
