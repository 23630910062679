import { useParams } from "react-router-dom";
import BusCompany from ".";

const BusCompanyWrapper = () => {
  const { busCompany } = useParams();

  if (!busCompany) return null;

  return <BusCompany id={busCompany} />;
};

export default BusCompanyWrapper;
