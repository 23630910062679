import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  setDoc,
  doc,
  getDoc,
  DocumentReference,
  query,
  orderBy,
} from "firebase/firestore/lite";
import { app } from "./firebase";

import {
  BusCompanies,
  BusLines,
  BusStops,
  Restaurants,
  Trip,
  Trips,
  TripStops,
} from "./schemas";

const db = getFirestore(app);

export const getRestaurants = async (): Promise<API.Restaurant[]> => {
  const restaurantsSnapshot = await getDocs(collection(db, "restaurants"));
  const restaurantList = restaurantsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return Restaurants.parse(restaurantList);
};

export const addRestaurant = async (
  restaurant: API.Restaurant
): Promise<void> => {
  const dbRestaurant: any = restaurant;
  delete dbRestaurant.id;
  await addDoc(collection(db, "restaurants"), dbRestaurant);
};

// BUS

export const getBusStops = async (): Promise<API.BusStop[]> => {
  const busStopsSnapshot = await getDocs(collection(db, "busStops"));
  const busStopList = busStopsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return BusStops.parse(busStopList);
};

export const addBusStop = async (busStop: API.BusStop): Promise<void> => {
  const dbBusStop: any = busStop;
  delete dbBusStop.id;
  await addDoc(collection(db, "busStops"), dbBusStop);
};

export const getTrips = async (
  busCompanyId: string,
  busLineId: string
): Promise<API.Trip[]> => {
  const tripsSnapshot = await getDocs(
    collection(db, `busCompany/${busCompanyId}/busLine/${busLineId}/trip`)
  );

  const tripsList = tripsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return Trips.parse(tripsList);
};

export const addTrip = async (
  busCompanyId: string,
  busLineId: string,
  trip: API.Trip
): Promise<void> => {
  const dbTrip: any = trip;
  delete dbTrip.id;
  await addDoc(
    collection(db, `busCompany/${busCompanyId}/busLine/${busLineId}/trip`),
    dbTrip
  );
};

export const getBusCompanies = async (): Promise<API.BusCompany[]> => {
  const companiesSnapshot = await getDocs(collection(db, `busCompany`));
  const companiesList = companiesSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return BusCompanies.parse(companiesList);
};

export const addBusLine = async (
  busCompanyId: string,
  busLine: API.BusLine
): Promise<void> => {
  await setDoc(doc(db, `busCompany/${busCompanyId}/busLine/${busLine.id}`), {
    name: busLine.name,
  });
};

export const getBusLines = async (
  busCompanyId: string
): Promise<API.BusLine[]> => {
  const busLinesSnapshot = await getDocs(
    collection(db, `busCompany/${busCompanyId}/busLine`)
  );
  const busLineList = busLinesSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return BusLines.parse(busLineList);
};

export const getTrip = async (
  busCompanyId: string,
  busLineId: string,
  tripId: string
): Promise<API.Trip> => {
  const tripDoc = await getDoc(
    doc(db, `busCompany/${busCompanyId}/busLine/${busLineId}/trip/${tripId}`)
  );

  const trip = {
    id: tripDoc.id,
    ...tripDoc.data(),
  };

  return Trip.parse(trip);
};

export const getTripStops = async (
  busCompanyId: string,
  busLineId: string,
  tripId: string
): Promise<API.TripStop[]> => {
  const tripStopsSnapshot = await getDocs(
    query(collection(
      db,
      `busCompany/${busCompanyId}/busLine/${busLineId}/trip/${tripId}/stop`
    ), orderBy("time"))
  );

  let tripStopList: API.TripStop[] = [];

  for (const tripStop of tripStopsSnapshot.docs) {
    const docData = tripStop.data();
    const busStop = await getDoc(docData.busStop);

    tripStopList.push({
      id: tripStop.id,
      time: docData.time,
      timeType: docData.timeType,
      busStop: { ...(busStop.data() as API.BusStop), id: busStop.id },
    });
  }

  return TripStops.parse(tripStopList);
};

export const addTripStop = async (
  busCompanyId: string,
  busLineId: string,
  tripId: string,
  tripStop: API.TripStop,
  busStopId: string
): Promise<void> => {
  const dbTripStop: any = tripStop;
  delete dbTripStop.id;
  dbTripStop.busStop = doc(db, `busStops/${busStopId}`);
  await addDoc(
    collection(
      db,
      `busCompany/${busCompanyId}/busLine/${busLineId}/trip/${tripId}/stop`
    ),
    dbTripStop
  );
};
