import React, { FormEvent, useEffect, useState } from "react";

import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { addTripStop, getBusStops } from "../../../../../database";
import AddBusStopForm from "./AddBusStopForm";
import GoogleMaps from "../../../../../components/GoogleMaps";
import Marker from "../../../../../components/GoogleMaps/Marker";

interface Props {
  tripId: string;
  busLineId: string;
  busCompanyId: string;
}

const AddTripStopForm: React.FC<Props> = ({
  tripId,
  busLineId,
  busCompanyId,
}) => {
  const position: API.Coordinate = {
    latitude: 32.645,
    longitude: -16.92,
  };

  const [markerPos, setMarkerPos] = useState<API.Coordinate>();

  const [busStops, setBusStops] = useState<API.BusStop[]>([]);

  const [tripStop, setTripStop] = useState<API.TripStop>({
    id: "",
    time: "00:00",
    timeType: "scheduled",
  });

  const [busStopId, setBusStopId] = useState("");

  useEffect(() => {
    getBusStops().then(setBusStops).catch(console.error);
  }, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    addTripStop(busCompanyId, busLineId, tripId, tripStop, busStopId);
  };

  return (
    <>
      <Box sx={{ maxWidth: "300px", p: 2, mt: 2, mb: 2 }} component={Paper}>
        <Typography variant="h6">Add a TripStop</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Time"
            variant="standard"
            fullWidth
            value={tripStop.time}
            onChange={(e) => setTripStop({ ...tripStop, time: e.target.value })}
            margin="normal"
            type="time"
          />
          <TextField
            label="Bus Stop (ID)"
            variant="standard"
            fullWidth
            value={busStopId}
            disabled
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 1 }}
            disabled={busStopId === ""}
          >
            add
          </Button>
        </form>
      </Box>

      <Stack direction="row" spacing={2}>
        <Box sx={{ flex: 1 }}>
          <GoogleMaps center={position} zoom={16}>
            {busStops.map((stop) => (
              <Marker
                key={stop.id}
                position={stop.coordinate}
                title={stop.name}
                onClick={() => setBusStopId(stop.id)}
              />
            ))}
            <Marker
              position={position}
              title="Marker"
              onChange={setMarkerPos}
              icon="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
              draggable
            />
          </GoogleMaps>
        </Box>

        <AddBusStopForm coordinate={markerPos} />
      </Stack>
    </>
  );
};

export default AddTripStopForm;
