import React from "react";

function Page404() {
  return (
    <div>
      <h2>404</h2>
    </div>
  );
}

export default Page404;
