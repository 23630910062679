import React from "react";

import Star from "@mui/icons-material/Star";
import StarOutline from "@mui/icons-material/StarOutline";
import { Stack } from "@mui/system";
import { IconButton, InputLabel } from "@mui/material";

interface Props {
  value: number;
  onChange: (value: number) => void;
  maxValue?: number;
}

const Stars: React.FC<Props> = ({ value, onChange, maxValue = 5 }) => {
  let stars = [];
  for (let i = 1; i <= maxValue; i++) {
    stars.push(value >= i ? true : false);
  }

  return (
    <Stack direction="row">
      <InputLabel sx={{ alignSelf: "center", mr: 1 }}>Stars</InputLabel>
      {stars.map((val, i) => {
        return (
          <IconButton key={i} onClick={() => onChange(i + 1)} size="small">
            {val ? <Star /> : <StarOutline />}
          </IconButton>
        );
      })}
    </Stack>
  );
};

export default Stars;
