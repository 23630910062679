import React, { useEffect, useState } from "react";
import { getTrip, getTripStops } from "../../../../../database";
import AddTripStopForm from "./AddTripStopForm";

interface Props {
  id: string;
  busLineId: string;
  busCompanyId: string;
}

const Trip: React.FC<Props> = ({ busCompanyId, busLineId, id }) => {
  const [trip, setTrip] = useState<API.Trip>();
  const [tripStops, setTripStops] = useState<API.TripStop[]>([]);

  useEffect(() => {
    getTrip(busCompanyId, busLineId, id).then(setTrip).catch(console.error);
    getTripStops(busCompanyId, busLineId, id)
      .then(setTripStops)
      .catch(console.error);
  }, [id]);

  return (
    <>
      <AddTripStopForm
        busCompanyId={busCompanyId}
        busLineId={busLineId}
        tripId={id}
      />
      Stops:
      {tripStops.map((stop) => (
        <div key={stop.id}>{stop.time} {stop.busStop?.name} </div>
      ))}
    </>
  );
};

export default Trip;
