import React, { useEffect, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { getTrips } from "../../../../database";
import AddTripForm from "./AddTripForm";

interface Props {
  id: string;
  companyId: string;
}

const BusLine: React.FC<Props> = ({ companyId, id }) => {
  const [trips, setTrips] = useState<API.Trip[]>([]);

  const { trip } = useParams();

  useEffect(() => {
    getTrips(companyId, id).then(setTrips).catch(console.error);
  }, [id]);

  return (
    <>
      {trip ? (
        <Outlet />
      ) : (
        <>
          <AddTripForm busCompanyId={companyId} busLineId={id} />

          {trips.map((trip) => (
            <div key={trip.id}>
              <Link to={trip.id}>Trip um {trip.startTime}</Link>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default BusLine;
