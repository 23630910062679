import React, { useContext, useEffect, useRef } from "react";
import { MapContext } from "./MyMap";

interface Props {
  position: API.Coordinate;
  title: string;
  onChange?: (coordinate: API.Coordinate) => void;
  onClick?: () => void;
  icon?: string;
  draggable?: boolean;
}

const Marker: React.FC<Props> = ({
  position,
  title,
  onChange,
  onClick,
  icon,
  draggable = false,
}) => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (map) {
      const marker = new google.maps.Marker({
        position: { lat: position.latitude, lng: position.longitude },
        map,
        draggable,
        title,
        icon,
      });
      if (onChange) {
        marker.addListener("dragend", ({ latLng }: any) => {
          onChange({ longitude: latLng.lng(), latitude: latLng.lat() });
        });
      }
      if (onClick) {
        marker.addListener("click", () => {
          onClick();
        });
      }
      return () => marker.unbindAll();
    }
  }, [map]);

  return <></>;
};

export default Marker;
