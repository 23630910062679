import React from "react";

import { Routes, Route } from "react-router-dom";

import Page404 from "./pages/404";
import Bus from "./pages/Bus";
import BusCompanyWrapper from "./pages/Bus/BusCompany/BusCompanyWrapper";
import BusLineWrapper from "./pages/Bus/BusCompany/BusLine/BusLineWrapper";
import TripWrapper from "./pages/Bus/BusCompany/BusLine/Trip/TripWrapper";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import Restaurants from "./pages/Restaurants";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="bus" element={<Bus />}>
          <Route path=":busCompany" element={<BusCompanyWrapper />}>
            <Route path=":busLine" element={<BusLineWrapper />}>
              <Route path=":trip" element={<TripWrapper />} />
            </Route>
          </Route>
        </Route>
        <Route path="restaurants" element={<Restaurants />} />

        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
}

export default App;
