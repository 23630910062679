import { z } from "zod";

// const isISODate = (date: string) => {
//   return !Number.isNaN(Date.parse(date));
// };

export const Restaurant = z.object({
  id: z.string(),
  coordinate: z.object({
    latitude: z.number(),
    longitude: z.number()
  }),
  name: z.string(),
  rating: z.number(),
});

export const Restaurants = z.array(Restaurant);

// export const Project = z.object({
//   id: z.number(),
//   date: z
//     .string()
//     .refine(isISODate, { message: "Not a valid ISO string date " }),
//   title: z.string(),
//   text: z.string(),
//   success_story: z.string().optional(),
//   url: z.string(),
//   active: z.boolean(),
// });

// export const Projects = z.array(Project);


export const Coordinate = z.object({
  longitude: z.number(),
  latitude: z.number(),
});

export const BusStop = z.object({
  id: z.string(),
  name: z.string(),
  coordinate: Coordinate,
});

export const BusStops = z.array(BusStop);

export const DayOfWeek = ["Mo-Fr", "Sa", "So", "H"] as const;

export const Trip = z.object({
  id: z.string(),
  startTime: z.string(),
  dayOfWeek: z.enum(DayOfWeek),
});

export const Trips = z.array(Trip);

export const TripStop = z.object({
  id: z.string(),
  busStop: BusStop.optional(),
  time: z.string(),
  timeType: z.enum(["scheduled", "distanceEstimation", "averageEstimation"])
});

export const TripStops = z.array(TripStop);

export const BusLine = z.object({
  id: z.string(),
  name: z.string(),
});

export const BusLines = z.array(BusLine);

export const BusCompany = z.object({
  id: z.string(),
  name: z.string(),
});

export const BusCompanies = z.array(BusCompany);