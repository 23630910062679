import React, { FormEvent, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { addBusLine, addTrip } from "../../../database";
import { DayOfWeek } from "../../../schemas";

interface Props {
  busCompanyId: string;
}

const AddBusLineForm: React.FC<Props> = ({ busCompanyId }) => {
  const [busLine, setBusLine] = useState<API.BusLine>({
    id: "",
    name: "",
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    addBusLine(busCompanyId, busLine);
  };

  return (
    <Box sx={{ maxWidth: "300px", p: 2, mt: 2 }} component={Paper}>
      <Typography variant="h6">Add a Bus Line</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Bus Line Name"
          variant="standard"
          fullWidth
          value={busLine.id}
          onChange={(e) =>
            setBusLine({ id: e.target.value, name: e.target.value })
          }
          margin="normal"
        />
        <Button type="submit" variant="contained" sx={{ mt: 1 }}>
          add
        </Button>
      </form>
    </Box>
  );
};

export default AddBusLineForm;
